import { ReactNode } from 'react';
import { Button, Box, Center, Flex, Text, Stack } from '@chakra-ui/react';
import { Link } from '@chakra-ui/next-js';
import { Logo } from '@/components/Icon';

type ErrorPageProps = {
  statusCode: number;
  statusText: string;
  message: string;
  subMessage: ReactNode;
};

export function ErrorPage({
  statusCode,
  statusText,
  message,
  subMessage,
}: ErrorPageProps) {
  return (
    <Stack h="100vh" bgColor="white">
      <Flex as="header" alignItems="center" p="24px">
        <Link href="/stores">
          <Logo />
        </Link>
      </Flex>
      <Center flexGrow="1" textAlign="center">
        <Stack>
          <Stack gap="0" lineHeight="1" color="blue.500">
            <Box as="span" fontSize="80px" fontWeight="bold">
              {statusCode}
            </Box>
            <Box as="span" fontSize="14px">
              {statusText}
            </Box>
          </Stack>
          <Stack>
            <Text fontSize="24px">{message}</Text>
            <Box fontSize="14px">{subMessage}</Box>
          </Stack>
          <Box mt="24px">
            <Link href="/stores">
              <Button variant="secondary">トップページに戻る</Button>
            </Link>
          </Box>
        </Stack>
      </Center>
    </Stack>
  );
}
