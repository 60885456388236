'use client';

import { useEffect } from 'react';
import { ErrorPage } from '@/components/views/Error';

export default function NotFoundPage() {
  useEffect(() => {
    document.title = 'Canly | Page Not Found';
  }, []);

  return (
    <ErrorPage
      statusCode={404}
      statusText="Not Found"
      message="お探しのページは見つかりませんでした"
      subMessage="お探しのページはURLが正しくないか、削除された可能性があります。"
    />
  );
}
